import React from "react"

export default function QuoteRange({ debug, budgetStart, budgetEnd, budgetMax }) {
    let filledWidth = (budgetStart / budgetMax * 100);
    let budgetWidth = ((budgetEnd - budgetStart) / budgetMax * 100);
    let budgetStopValue = budgetMax / 10;

    function formatK(val) {
        return 'R'+Math.round(val/1000)+'k';
    }

    return (
        <div className="quote-range">
            <p className="quote-currency">Price in ZAR</p>
            <h5 className="text-center mb-2">Estimated Project Budget</h5>
            <div className="quote-range-stops">
                {Array(10).fill(1).map((el, i) =>
                    <div key={i} className="quote-range-stop" style={{ left: ((i+1)*10)+'%' }}>{formatK(budgetStopValue*(i+1))}</div>
                )}
            </div>
            <div className="quote-range-bar">
                <div className="quote-range-filled" style={{ width: filledWidth+'%' }}></div>
                <div className="quote-range-budget" style={{ width: budgetWidth+'%' }}></div>
            </div>
        </div>
    )
}