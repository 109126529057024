import React from 'react';

export default ({ validate, label, name, options, text, value, className = '', error, errors = [], ...props }) => {
	return (
        <div className="checkboxes-group">
			<div className={`form-field checks-field ${className}`}>
				{label && (
					<label className="form-label">
						{label}
					</label>
				)}

				{options.map(({title, default_value = false}, checkboxIndex) => {
					return (
						<div className="check-row" key={checkboxIndex}>
							<input
								id={`${name}-${checkboxIndex}`}
								name={name}
								type="checkbox"
								value={title}
                                defaultChecked={default_value}
								{...props}
								className={`form-check mr-2 ${errors[name] ? 'error' : ''}`}
								ref={validate}
							/>
							<label aria-label={title} className="cursor-pointer check-label" htmlFor={`${name}-${checkboxIndex}`} dangerouslySetInnerHTML={{__html: '<span></span>'+title}}></label>
						</div>
					);
				})}

				{errors[name] && <div className="form-error">{error}</div>}
			</div>
		</div>
	);
};
