import React from 'react';
import DownArrow from "../assets/img/icons/expand-arrow.svg"

export default ({ validate, label, name, options = [], className = '', children, error, errors = [], ...props }) => {
	return (
        <div className={`form-field ${className}`}>
			{label && (
				<label className="form-label" htmlFor={name}>
					{label}
				</label>
			)}

			<DownArrow className="down-arrow" />

			<select
				name={name}
				{...props}
                className={`form-select ${errors[name] ? 'error' : ''}`}
                ref={validate}
			>
                <option value="">Please select...</option>
                {options.map(({title}, selectIndex) => {
                    return (
                    	<option key={selectIndex} value={title}>{title}</option>
                    );
                })}
				{children}
			</select>
            {errors[name] && <div className="form-error">{error}</div>}
		</div>
	);
};
