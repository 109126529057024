import React from 'react';
import DatePicker from 'react-datepicker'
import "../assets/css/react-datepicker.min.css"

export default ({ onChange, selected, validate, label, placeholder, name, className = '', error, errors = [], ...props }) => {
	return (
        <div className={`form-field ${className}`}>
			{label && (
				<label className="form-label caption-group-caption" htmlFor={name}>
					{label}
				</label>
			)}
            <DatePicker
                id={name}
                placeholder={placeholder}
                className={`form-input ${errors[name] ? 'error' : ''}`}
                showYearDropdown
                dateFormat="yyyy-MM-dd"
                onChange={(val) => onChange(val)}
				selected={selected}
			/>
            {errors[name] && <div className="form-error">{error}</div>}
		</div>
	);
};
