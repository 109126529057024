import React, {useState} from "react"
import { useForm, Controller } from "react-hook-form"
import InputText from "../fields/text"
import InputTextarea from "../fields/textarea"
import InputDate from "../fields/date"
import InputSelect from "../fields/select"
import InputNumber from "../fields/number"
import InputCheck from "../fields/checkbox"
import InputChecks from "../fields/checkboxes"
import InputRadios from "../fields/radios"
import QuoteData from "../json/get-a-quote.json"
import QuoteDebug from "../components/quoteDebug"
import axios from "axios"

export default function QuoteForm({ debug, budgetStart, budgetEnd, budgetMax, setBudgetStart, setBudgetEnd, setBudgetMax, activeSection, setActiveSection }) {
    const formType = 'Quote';
    const formUrl = 'https://traktion.app/forms/send/';
    const successMsg = '<strong>Your brief was sent successfully.</strong><br />We\'ll respond with a quote as soon as possible.';
    const [ sending, setSending ] = useState(false);
    const [ success, setSuccess ] = useState(false);
    const isBrowser = typeof window !== `undefined`;
    const { trigger, control, register, handleSubmit, errors, watch } = useForm({ mode: 'onChange' });
    const formData = watch();
    let validation = [];

    QuoteData.map(({ sectionTitle, sectionSlug, questions }, index) => {
        validation.push([]);
        questions.map(({ title, slug, input_type, required, default_value, hide_if, show_if, answers }, ind) => {
            if(required) {
                validation[index].push(slug);
            }
            return validation;
        });
        return validation;
    });

    function getValidateObject(required, email = false) {
        const val = (email === true)
            ? {
                required: required,
                pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
                }
            }
            : {
                required: required
            }
        return val;
    }

    function getOffset(el) {
        var top = 0;
        do {
            top += el.offsetTop  || 0;
            el = el.offsetParent;
        } while(el);

        return top-90;
    }

    function scrollToEl(id) {
        let offset = 0;
        if(id !== 'top') {
            const el = document.getElementById(id);
            offset = getOffset(el);
        }
        if(isBrowser){
            setTimeout(function() {
                if(window.scrollY > offset) {
                    window.scrollTo({ top: offset, behavior: 'smooth' });
                }
            }, 1);
        }
    }

    function goBack(e) {
        e.preventDefault();
        setActiveSection(activeSection-1);
        scrollToEl('quote-form');
    }

    async function goNext(e) {
        e.preventDefault();
        const result = await trigger(validation[activeSection-1]);
        if(result || debug) {
            setActiveSection(activeSection+1);
        }
        scrollToEl('quote-form');
    }

    function onSubmit(data) {
        setSending(true);
        data.type = formType;
        axios.post(formUrl, data).then(res => {
            setSending(false);
            if(res.data.success === true) {
                setSuccess(true);
                window.scrollTo(0,0);
            } else {
                alert('Something went wrong, please email us - info@artificial.studio');
                console.log(res);
            }
        });
    }

    return (
        <div className={`w-full flex flex-wrap`}>
            {debug &&
                <QuoteDebug
                    activeSection={activeSection}
                    setActiveSection={setActiveSection}
                    setBudgetStart={setBudgetStart}
                    setBudgetEnd={setBudgetEnd}
                    setBudgetMax={setBudgetMax}
                    budgetStart={budgetStart}
                    budgetEnd={budgetEnd}
                    budgetMax={budgetMax}
                    formData={formData}
                />
            }
            <form id="quote-form" onSubmit={handleSubmit(onSubmit)} className={`w-full mb-12 ${success ? 'text-center' : ''}`}>
                {success &&
                    <>
                        <h2>Thank you!</h2>
                        <p className="cl-primary" dangerouslySetInnerHTML={{__html: successMsg}}></p>
                    </>
                }

                {!success &&
                    <>
                        {QuoteData.map(({ sectionTitle, sectionSlug, questions }, index) => {
                            return (
                                <div id={`section`+(index+1)} key={index} className={`quote-form-section quote-form-section-${sectionSlug} ${activeSection === (index+1) ? 'active': ''}`}>
                                    <h2>{sectionTitle}</h2>

                                    {questions.map(({ title, slug = '', input_type, required = false, default_value, hide_if, show_if, answers, from, to }, ind) => {
                                        const isEmail = (slug.indexOf('email') > -1);
                                        let show_class = '';

                                        // show_if logic
                                        if(typeof show_if !== 'undefined') {
                                            show_class = 'hidden';
                                            const parts = show_if.split('=');
                                            const required_field = parts[0];
                                            const required_value = parts[1];
                                            const actual_value = formData[required_field];
                                            if(typeof actual_value !== 'undefined' && actual_value !== null && (actual_value.toString() === required_value.toString() || actual_value.toString() === required_value.toString().replace('false', 'No').replace('true', 'Yes'))) {
                                                show_class = '';
                                            }
                                        }

                                        // hide_if logic
                                        if(typeof hide_if !== 'undefined') {
                                            show_class = '';
                                            const parts = hide_if.split('=');
                                            const required_field = parts[0];
                                            const required_value = parts[1];
                                            const actual_value = formData[required_field];
                                            if(typeof actual_value !== 'undefined' && actual_value !== null && (actual_value.toString() === required_value.toString() || actual_value.toString() === required_value.toString().replace('false', 'No').replace('true', 'Yes'))) {
                                                show_class = 'hidden';
                                            }
                                        }

                                        return (
                                            <div id={`quote-field-${ind}`} key={ind} className={show_class}>
                                                {input_type === 'text' &&
                                                    <InputText
                                                        label={title}
                                                        name={slug}
                                                        error={`Please enter ${title.toLowerCase()}`}
                                                        validate={register(getValidateObject(required, isEmail))}
                                                        errors={errors}
                                                    />
                                                }
                                                {input_type === 'textarea' &&
                                                    <InputTextarea
                                                        label={title}
                                                        name={slug}
                                                        error={`Please enter ${title.toLowerCase()}`}
                                                        validate={register(getValidateObject(required))}
                                                        errors={errors}
                                                    />
                                                }
                                                {input_type === 'title' &&
                                                    <div className={``}>
                                                        <h4>{title}</h4>
                                                    </div>
                                                }
                                                {input_type === 'note' &&
                                                    <div className={``}>
                                                        <label className="form-label">{title}</label>
                                                    </div>
                                                }
                                                {input_type === 'check' &&
                                                    <InputCheck
                                                        label={title}
                                                        name={slug}
                                                        text={answers[0]['title']}
                                                        error="Please select at least one option"
                                                        validate={register(getValidateObject(required))}
                                                        errors={errors}
                                                    />
                                                }
                                                {input_type === 'checks' &&
                                                    <InputChecks
                                                        label={title}
                                                        name={slug}
                                                        options={answers}
                                                        error="Please select at least one option"
                                                        validate={register(getValidateObject(required))}
                                                        errors={errors}
                                                    />
                                                }
                                                {input_type === 'radios' &&
                                                    <InputRadios
                                                        label={title}
                                                        name={slug}
                                                        options={answers}
                                                        defaultValue={default_value}
                                                        error="Please select"
                                                        validate={register(getValidateObject(required))}
                                                        errors={errors}
                                                    />
                                                }
                                                {input_type === 'select' &&
                                                    <InputSelect
                                                        label={title}
                                                        name={slug}
                                                        options={answers}
                                                        error="Please select at least one option"
                                                        validate={register(getValidateObject(required))}
                                                        errors={errors}
                                                    />
                                                }
                                                {input_type === 'number' &&
                                                    <InputNumber
                                                        label={title}
                                                        name={slug}
                                                        from={from}
                                                        to={to}
                                                        error="Please select"
                                                        validate={register(getValidateObject(required))}
                                                        errors={errors}
                                                    />
                                                }
                                                {input_type === 'date' &&
                                                    <Controller
                                                        name={slug}
                                                        control={control}
                                                        defaultValue=""
                                                        rules={{ required: required }}
                                                        render={({ onChange, value }) => (
                                                            <InputDate
                                                                label={title}
                                                                error="Please select date"
                                                                selected={value}
                                                                errors={errors}
                                                                onChange={onChange}
                                                            />
                                                        )}
                                                    />
                                                }
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        })}
                        <div className="form-field flex mt-12">
                            {activeSection > 1 &&
                                <button onClick={goBack} className="btn btn-lg btn-primary btn-back">Back</button>
                            }
                            {activeSection < QuoteData.length &&
                                <button onClick={goNext} className="btn btn-lg btn-primary btn-next">Next</button>
                            }
                            {activeSection === QuoteData.length &&
                                <button type="submit" className="btn btn-lg btn-primary btn-next">Submit</button>
                            }
                        </div>
                    </>
                }
            </form>
        </div>
    );
}