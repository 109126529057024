import React from "react"

export default function QuoteDebug({ formData, debug, setBudgetStart, setBudgetEnd, setBudgetMax, budgetStart, budgetEnd, budgetMax, activeSection, setActiveSection }) {
    return (
        <div id="debug" className="w-1/2 pr-16">
            <div className="bg-blue p-8">
                <h3 className="mt-2">Debug</h3>
                <p>
                    <strong>activeSection: </strong>{activeSection}<br />
                    <strong>budgetStart: </strong>{budgetStart}<br />
                    <strong>budgetEnd: </strong>{budgetEnd}<br />
                    <strong>budgetMax: </strong>{budgetMax}<br />
                </p>
                <h5>formData:</h5>
                <small>
                    {Object.keys(formData).map((key, ind) => {
                        return (
                            <div key={ind}><strong className="font-medium">{key}: </strong>{formData[key]}</div>
                        );
                    })}
                </small>
            </div>
        </div>
    )
}