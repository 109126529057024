import React from 'react';

export default ({ validate, label, name, text = '', value, className = '', error, errors = [], ...props }) => {
	return (
        <div className={`form-field check-field ${className}`}>
			{label && (
				<label className="form-label">
					{label}
				</label>
			)}

			<div className="check-row">
				<input
					id={name}
					name={name}
					type="checkbox"
					{...props}
					className={`form-check mr-2 ${errors[name] ? 'error' : ''}`}
                    ref={validate}
				/>
				<label aria-label={text} className="cursor-pointer check-label" htmlFor={name} dangerouslySetInnerHTML={{__html: '<span></span>'+text}}></label>
			</div>
            {errors[name] && <div className="form-error">{error}</div>}
		</div>
	);
};
