import React from 'react';

export default ({ validate, label, name, options, className = '', defaultValue = '', error, errors = [], ...props }) => {
	return (
        <div className={`form-field radio-field ${className}`}>
			{label && (
				<label className="form-label">
					{label}
				</label>
			)}
			<div className={`radio-options`}>
				{options.map(({title, value}, radioIndex) => {
					return (
						<div key={radioIndex} className="radio-option">
							<input
								id={`${name}-${radioIndex}`}
								name={name}
								type="radio"
								value={title}
                                defaultChecked={value === defaultValue}
								{...props}
								className={`form-radio mr-2 ${errors[name] ? 'error' : ''}`}
								ref={validate}
							/>
							<label className="cursor-pointer radio-label" htmlFor={`${name}-${radioIndex}`}>
								<span></span>
								{title}
							</label>
						</div>
					)
				})}
			</div>
            {errors[name] && <div className="form-error">{error}</div>}
		</div>
	);
};
